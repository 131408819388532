import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

const cc = localStorage.getItem('pms') ? JSON.parse(localStorage.getItem('pms')) : [];

const DEFAULT_STATE = {
  permissions: cc,
  loading: false
};

export const DataContext = React.createContext(DEFAULT_STATE);

class DataProvider extends React.Component {
  state = DEFAULT_STATE;

  async componentWillMount() {
    this.setState({ loading: true });
    if (this.props.auth.user.id) {
      await axios.get('/api/user/update/' + this.props.auth.user.id).then((res) => {
        let pms = [];
        res.data.roles.map((r) => {
          pms = [...pms, ...r.permissions];
        });

        // console.log('sd', pms);
        this.setState({ permissions: pms, loading: false });
        localStorage.setItem('pms', JSON.stringify(pms));
      });
    }
  }

  render() {
    return (
      <DataContext.Provider
        value={{
          ...this.state
        }}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(DataProvider);
