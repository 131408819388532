import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import { useTranslation } from 'react-i18next';
import LocalMovies from '@material-ui/icons/LocalMoviesOutlined';
import EventNote from '@material-ui/icons/EventNoteOutlined';
import Group from '@material-ui/icons/GroupOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import Business from '@material-ui/icons/BusinessOutlined';
import SubtitlesIcon from '@material-ui/icons/SubtitlesOutlined';
import FastfoodIcon from '@material-ui/icons/FastfoodOutlined';
import TranslateIcon from '@material-ui/icons/Translate';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import { SidebarNav } from './components';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = (props) => {
  const { open, variant, auth, onClose, permissions, className, ...rest } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const isAdmin = auth.isAuthenticated ? auth.user.email === 'admin@sinemans.com' : false;

  console.log('----', props);

  const pages = [
    {
      title: t('Dashboard'),
      href: '/dashboard',
      active: permissions.includes('Dahsboard.View') || isAdmin,
      icon: <DashboardIcon />
    },
    {
      title: t('Shows'),
      href: '#',
      icon: <LocalMovies />,
      active: !!permissions.find((p) => p.startsWith('Shows.')) || isAdmin,
      items: [
        {
          title: t('All Shows'),
          href: '/movies',
          active: permissions.includes('Shows.View') || isAdmin
        },
        {
          title: t('Shows Categories'),
          href: '/movie-category',
          active: permissions.includes('ShowsCategories.View') || isAdmin
        },
        {
          title: t('Countries'),
          href: '/country',
          active: permissions.includes('Countries.View') || isAdmin
        },
        {
          title: t('Genres'),
          href: '/genre',
          active: permissions.includes('Genres.View') || isAdmin
        }
      ]
    },
    {
      title: t('Repertuar'),
      href: '/repertuar',
      active: permissions.includes('Repertuar.View') || isAdmin,
      icon: <EventNote />
    },
    {
      title: t('Hall'),
      icon: <Business />,
      active: !!permissions.find((p) => p.startsWith('Halls.')) || isAdmin,
      href: '#',
      items: [
        {
          title: t('All Halls'),
          href: '/hall',
          active: permissions.includes('Halls.View') || isAdmin
        },
        {
          title: t('Price Category'),
          href: '/price-category',
          active: permissions.includes('PriceCategory.View') || isAdmin
        },
        {
          title: t('Seat Zone'),
          href: '/seat-zone',
          active: permissions.includes('SeatZone.View') || isAdmin
        },
        {
          title: t('Time Adjustments'),
          href: '/time-adjustment',
          active: permissions.includes('TimeAdjustments.View') || isAdmin
        }
      ]
    },
    {
      title: t('Ticket'),
      href: '#',
      icon: <SubtitlesIcon />,
      active: !!permissions.find((p) => p.startsWith('Ticket.')) || isAdmin,
      items: [
        {
          title: t('Create Ticket'),
          href: '/ticket',
          active: permissions.includes('Ticket.View') || isAdmin
        },
        {
          title: t('Tickets'),
          href: '/ticket/list',
          active: permissions.includes('Ticket.List') || isAdmin
        }
      ]
    },
    {
      title: t('Buffet'),
      href: '#',
      icon: <FastfoodIcon />,
      active:
        permissions.includes('Ingredient.View') ||
        permissions.includes('Product.View') ||
        permissions.includes('ProductCategory.View') ||
        permissions.includes('PosTerminal.View') ||
        permissions.includes('Sales.View') ||
        isAdmin,
      items: [
        {
          title: t('Ingredient'),
          href: '/ingredient',
          active: permissions.includes('Ingredient.View') || isAdmin
        },
        {
          title: t('Product'),
          href: '/product',
          active: permissions.includes('Product.View') || isAdmin
        },
        {
          title: t('Product Category'),
          href: '/product-category',
          active: permissions.includes('ProductCategory.View') || isAdmin
        },
        {
          title: t('POS Terminal'),
          href: '/pos-terminal',
          active: permissions.includes('PosTerminal.View') || isAdmin
        },
        {
          title: t('Sales'),
          href: '/sale',
          active: permissions.includes('Sales.View') || isAdmin
        }
      ]
    },
    {
      title: t('Users'),
      href: '/users',
      active: permissions.includes('Users.View') || isAdmin,
      icon: <Group />
    },
    {
      title: t('Roles'),
      href: '/role',
      active: permissions.includes('Roles.View') || isAdmin,
      icon: <HttpsOutlinedIcon />
    },
    {
      title: t('Translation'),
      href: '/translation',
      active: permissions.includes('Translation.View') || isAdmin,
      icon: <TranslateIcon />
    },
    {
      title: t('Settings'),
      active: permissions.includes('Settings.View') || isAdmin,
      href: '/profile',
      icon: <SettingsIcon />
    }
  ];

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Sidebar);
