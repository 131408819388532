import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithAuth, RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import DataProvider from './DataProvider';

const Dashboard = lazy(() => import('./views/Dashboard'));
const User = lazy(() => import('./views/User'));
const UserCreate = lazy(() => import('./views/User/Create'));
const UserUpdate = lazy(() => import('./views/User/Update'));
const Movies = lazy(() => import('./views/Movies'));
const MovieCreate = lazy(() => import('./views/Movies/Create'));
const MovieUpdate = lazy(() => import('./views/Movies/Update'));
const MovieCategory = lazy(() => import('./views/MovieCategory'));
const Genre = lazy(() => import('./views/Genre'));
const Country = lazy(() => import('./views/Country'));
const Role = lazy(() => import('./views/Role'));
const Ingredient = lazy(() => import('./views/Ingredient'));
const IngredientCreate = lazy(() => import('./views/Ingredient/Create'));
const IngredientUpdate = lazy(() => import('./views/Ingredient/Update'));
const IngredientAdd = lazy(() => import('./views/Ingredient/Add'));
const IngredientUpdatePurchase = lazy(() => import('./views/Ingredient/UpdatePurchase'));
const IngredientList = lazy(() => import('./views/Ingredient/List'));
const ProductCategory = lazy(() => import('./views/ProductCategory'));
const ProductCategoryCreate = lazy(() => import('./views/ProductCategory/Create'));
const ProductCategoryUpdate = lazy(() => import('./views/ProductCategory/Update'));
const Product = lazy(() => import('./views/Product'));
const ProductCreate = lazy(() => import('./views/Product/Create'));
const ProductUpdate = lazy(() => import('./views/Product/Update'));
const Repertuar = lazy(() => import('./views/Repertuar'));
const RepertuarCreate = lazy(() => import('./views/Repertuar/Create'));
const RepertuarUpdate = lazy(() => import('./views/Repertuar/Update'));
const PriceCategory = lazy(() => import('./views/PriceCategory'));
const PriceCategoryCreate = lazy(() => import('./views/PriceCategory/Create'));
const PriceCategoryUpdate = lazy(() => import('./views/PriceCategory/Update'));
const SeatZone = lazy(() => import('./views/SeatZone'));
const SeatZoneCreate = lazy(() => import('./views/SeatZone/Create'));
const SeatZoneUpdate = lazy(() => import('./views/SeatZone/Update'));
const TimeAdjustment = lazy(() => import('./views/TimeAdjustment'));
const Profile = lazy(() => import('./views/Profile'));
const Ticket = lazy(() => import('./views/Ticket/Ticket'));
const TicketUpdate = lazy(() => import('./views/Ticket/Update'));
const TicketList = lazy(() => import('./views/Ticket/List'));
const SignUp = lazy(() => import('./views/SignUp'));
const Hall = lazy(() => import('./views/Hall'));
const HallCreate = lazy(() => import('./views/Hall/Create'));
const HallUpdate = lazy(() => import('./views/Hall/Update'));
const HallChooseSeats = lazy(() => import('./views/Hall/ChooseSeats'));
const Translation = lazy(() => import('./views/Translation'));
const Sale = lazy(() => import('./views/Sale'));
const SignIn = lazy(() => import('./views/SignIn'));
const PosTerminal = lazy(() => import('./views/PosTerminal'));
const NotFound = lazy(() => import('./views/NotFound'));

const Routes = (props) => {
  return (
    <DataProvider>
      <RouteWithLayout component={SignUp} exact layout={MinimalLayout} path="/sign-up" />
      <RouteWithLayout component={SignIn} exact layout={MinimalLayout} path="/sign-in" />
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <RouteWithAuth component={Dashboard} exact layout={MainLayout} path="/dashboard" can="Dahsboard.View" />

        <RouteWithAuth component={User} exact layout={MainLayout} path="/users" can="Users.View" />
        <RouteWithAuth component={UserCreate} exact layout={MainLayout} path="/users/create" can="Users.Create" />
        <RouteWithAuth component={UserUpdate} exact layout={MainLayout} path="/users/update/:id" can="Users.Update" />

        <RouteWithAuth component={Movies} exact layout={MainLayout} path="/movies" can="Shows.View" />
        <RouteWithAuth component={MovieCreate} exact layout={MainLayout} path="/movies/create" can="Shows.Create" />
        <RouteWithAuth component={MovieUpdate} exact layout={MainLayout} path="/movies/update/:id" can="Shows.Update" />

        <RouteWithAuth
          component={MovieCategory}
          exact
          layout={MainLayout}
          path="/movie-category"
          can="ShowsCategories.View"
        />
        <RouteWithAuth component={Genre} exact layout={MainLayout} path="/genre" can="Genres.View" />
        <RouteWithAuth component={Country} exact layout={MainLayout} path="/country" can="Countries.View" />
        <RouteWithAuth component={Role} exact layout={MainLayout} path="/role" can="Roles.View" />

        <RouteWithAuth component={Ingredient} exact layout={MainLayout} path="/ingredient" can="Ingredient.View" />
        <RouteWithAuth
          component={IngredientCreate}
          exact
          layout={MainLayout}
          path="/ingredient/create"
          can="Ingredient.Create"
        />
        <RouteWithAuth
          component={IngredientUpdate}
          exact
          layout={MainLayout}
          path="/ingredient/update/:id"
          can="Ingredient.Update"
        />
        <RouteWithAuth
          component={IngredientAdd}
          exact
          layout={MainLayout}
          path="/ingredient/add/:id/:measure"
          can="Ingredient.Create"
        />
        <RouteWithAuth
          component={IngredientUpdatePurchase}
          exact
          layout={MainLayout}
          path="/ingredient/update-purchase/:id/:pid/:measure"
        />
        <RouteWithAuth
          component={IngredientList}
          exact
          layout={MainLayout}
          path="/ingredient/list/:id"
          can="IngredientPurchase.View"
        />
        <RouteWithAuth component={Product} exact layout={MainLayout} path="/product" can="Product.View" />
        <RouteWithAuth
          component={ProductCreate}
          exact
          layout={MainLayout}
          path="/product/create"
          can="Product.Create"
        />
        <RouteWithAuth
          component={ProductUpdate}
          exact
          layout={MainLayout}
          path="/product/update/:id"
          can="Product.Update"
        />
        <RouteWithAuth
          component={ProductCategory}
          exact
          layout={MainLayout}
          path="/product-category"
          can="ProductCategory.View"
        />
        <RouteWithAuth
          component={ProductCategoryCreate}
          exact
          layout={MainLayout}
          path="/product-category/create"
          can="ProductCategory.Create"
        />
        <RouteWithAuth
          component={ProductCategoryUpdate}
          exact
          layout={MainLayout}
          path="/product-category/update/:id"
          can="ProductCategory.Update"
        />
        <RouteWithAuth component={Repertuar} exact layout={MainLayout} path="/repertuar" can="Repertuar.View" />
        <RouteWithAuth
          component={RepertuarCreate}
          exact
          layout={MainLayout}
          path="/repertuar/create"
          can="Repertuar.Create"
        />
        <RouteWithAuth
          component={RepertuarUpdate}
          exact
          layout={MainLayout}
          path="/repertuar/update/:id"
          can="Repertuar.Update"
        />
        <RouteWithAuth
          component={TimeAdjustment}
          exact
          layout={MainLayout}
          path="/time-adjustment"
          can="TimeAdjustments.View"
        />
        <RouteWithAuth component={Profile} exact layout={MainLayout} path="/profile" can="Settings.View" />
        <RouteWithAuth
          component={PriceCategory}
          exact
          layout={MainLayout}
          path="/price-category"
          can="PriceCategory.View"
        />
        <RouteWithAuth
          component={PriceCategoryCreate}
          exact
          layout={MainLayout}
          path="/price-category/create"
          can="PriceCategory.Create"
        />
        <RouteWithAuth
          component={PriceCategoryUpdate}
          exact
          layout={MainLayout}
          path="/price-category/update/:id"
          can="PriceCategory.Update"
        />
        <RouteWithAuth component={SeatZone} exact layout={MainLayout} path="/seat-zone" can="SeatZone.View" />
        <RouteWithAuth
          component={SeatZoneCreate}
          exact
          layout={MainLayout}
          path="/seat-zone/create"
          can="SeatZone.Create"
        />
        <RouteWithAuth
          component={SeatZoneUpdate}
          exact
          layout={MainLayout}
          path="/seat-zone/update/:id"
          can="SeatZone.Update"
        />
        <RouteWithAuth component={Hall} exact layout={MainLayout} path="/hall" can="Halls.View" />
        <RouteWithAuth component={HallCreate} exact layout={MainLayout} path="/hall/create" can="Halls.Create" />
        <RouteWithAuth component={HallUpdate} exact layout={MainLayout} path="/hall/update/:id" can="Halls.Update" />
        <RouteWithAuth
          component={HallChooseSeats}
          exact
          layout={MinimalLayout}
          path="/hall/choose-seats"
          can="Ticket.ChooseSeats"
        />
        <RouteWithAuth component={Ticket} exact layout={MainLayout} path="/ticket" can="Ticket.View" />
        <RouteWithAuth component={TicketUpdate} exact layout={MainLayout} path="/ticket/update/:id" can="Ticket.DetailsView" />
        <RouteWithAuth component={TicketList} exact layout={MainLayout} path="/ticket/list" can="Ticket.List" />
        <RouteWithAuth component={Sale} exact layout={MainLayout} path="/sale" />
        <RouteWithAuth component={Translation} exact layout={MainLayout} path="/translation" can="Translation.View" />
        <RouteWithAuth component={Translation} exact layout={MainLayout} path="/translation/:l" can="" />
        <RouteWithAuth
          component={PosTerminal}
          exact
          layout={MinimalLayout}
          path="/pos-terminal"
          can="PosTerminal.View"
        />
      </Switch>
      <RouteWithLayout component={NotFound} exact layout={MinimalLayout} path="/not-found" />
    </DataProvider>
  );
};

export default Routes;
