import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className, ...rest } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="body1">
        &copy;{' '}
  
         <MovieFilterIcon style={{verticalAlign: 'middle',width:18}}/> {t('Sinemans')} 
        
          &nbsp; 2019
      </Typography>
      <Typography variant="caption">
        {t('Created with Balans Studio')}
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
