/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = (e, title) => {
    if (title === open) {
      setOpen(false);
    } else {
      setOpen(title);
    }
  };
  //

  console.log('pages', pages);
  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages
        .filter((p) => p.active)
        .map((page, index) => {
          return page.items ? (
            <React.Fragment key={index}>
              <ListItem className={classes.item} disableGutters onClick={(e) => handleClick(e, page.title)}>
                <Button className={classes.button} component={CustomRouterLink} to={page.href}>
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                  {open === page.title ? (
                    <ExpandLess style={{ marginLeft: 'auto' }} />
                  ) : (
                    <ExpandMore style={{ marginLeft: 'auto' }} />
                  )}
                </Button>
              </ListItem>
              <Collapse in={open === page.title} timeout="auto" unmountOnExit>
                <List {...rest} className={clsx(classes.root, className)}>
                  {page.items
                    .filter((p) => p.active)
                    .map((sm, index) => (
                      <ListItem className={`${classes.nested} ${classes.item}`} disableGutters key={index}>
                        <Button
                          activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={sm.href}>
                          {sm.title}
                        </Button>
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            </React.Fragment>
          ) : (
            <ListItem className={classes.item} disableGutters key={page.title}>
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}>
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
            </ListItem>
          );
        })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
