import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DataContext } from '../../DataProvider';

const RouteWithAuth = (props) => {
  const { layout: Layout, component: Component, can, auth, ...rest } = props;

  return (
    <DataContext.Consumer>
      {(context) => {

        const isAdmin = auth.isAuthenticated ? auth.user.email === 'admin@sinemans.com' : false;
        const canView = context.permissions.includes(can);

        return (
          <Route
            {...rest}
            render={(matchProps) =>
              auth.isAuthenticated === true ? (
                context.loading ? null : canView || isAdmin ? (
                  <Layout permissions={context.permissions}>
                    <Component {...matchProps} />
                  </Layout>
                ) : (
                  <h1>Access denied</h1>
                )
              ) : (
                <Redirect to="/sign-in" />
              )
            }
          />
        );
      }}
    </DataContext.Consumer>
  );
};

RouteWithAuth.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(RouteWithAuth);
