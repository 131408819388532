import { combineReducers } from "redux";
import authReducer from "./authReducer";
import notifierReducer from "./notifierReducer";
import errorReducer from "./errorReducer";

export default combineReducers({
  auth: authReducer,
  notifier: notifierReducer,
  errors: errorReducer
});
