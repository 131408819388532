import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../../../actions/authActions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  topbarTitle: {
    color: '#ffffff'
  }
}));

const Languages = [
  { label: 'English', code: 'English' },
  { label: 'Azərbaycanca', code: 'Azerbaijan' }
];

const Topbar = (props) => {
  const { className, onSidebarOpen, logoutUser, auth, ...rest } = props;
  const history = useHistory();

  const { user } = auth;

  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [notifications] = useState([]);

  const onLogoutClick = (e) => {
    e.preventDefault();
    logoutUser();
    history.push('/sign-in');
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  // const changeLanguage = lng => {
  //   i18n.changeLanguage(lng);
  //   setAnchorEl(null);
  // };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <Typography className={classes.topbarTitle} variant="h4">
            {t('Sinemans')}
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />

        <IconButton color="inherit">
          <Badge overlap="rectangular" badgeContent={notifications.length} color="default" variant="dot">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Button aria-controls="simple-menu" color="inherit" aria-haspopup="true" onClick={handleClick}>
          {user.name.split(' ')[0]}
          <ExpandMoreIcon />
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem key={'logout'} onClick={onLogoutClick}>
            {t('Logout')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Topbar);
